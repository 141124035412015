import { cva } from "class-variance-authority";
import { cn } from "../utils";

export interface LoadingSpinnerProps {
    className?: string;
    size?: "sm" | "md";
    color?: "default";
}

const spinnerVariants = cva("flex shrink-0 animate-spin rounded-full", {
    variants: {
        size: {
            sm: "size-6 border-[3px]", // todo: add border-width values to tailwind config?
            md: "size-10 border-[6px]"
        },
        color: {
            default: "border-stroke-primary border-b-stroke-secondary-invert"
        }
    },
    defaultVariants: {
        size: "md",
        color: "default"
    }
});

function LoadingSpinner(props: LoadingSpinnerProps) {
    const { size, color, className } = props;
    return <span role="progressbar" aria-label="Loading" className={cn(spinnerVariants({ size, className }))}></span>;
}

export default LoadingSpinner;
